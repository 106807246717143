@import "open-color/open-color.scss";

:root {
  --button-gray-1: #{$oc-gray-2};
  --button-gray-2: #{$oc-gray-4};
  --button-gray-3: #{$oc-gray-5};
  --button-blue: #{$oc-blue-2};
}

.ToolIcon {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-family: Cascadia;
  cursor: pointer;
  background-color: var(--button-gray-1);
  -webkit-tap-highlight-color: transparent;
  border-radius: var(--space-factor);
}

.ToolIcon__icon {
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: var(--space-factor);

  svg {
    position: relative;
    height: 1em;
  }

  & + .ToolIcon__label {
    margin-inline-start: 0;
  }
}

.ToolIcon__label {
  font-family: var(--ui-font);
  margin: 0 0.8em;
  text-overflow: ellipsis;
}

.ToolIcon_size_s .ToolIcon__icon {
  width: 1.4rem;
  height: 1.4rem;
  font-size: 0.8em;
}

.ToolIcon_type_button {
  padding: 0;
  border: none;
  margin: 0;
  font-size: inherit;

  &:hover {
    background-color: var(--button-gray-1);
  }
  &:active {
    background-color: var(--button-gray-2);
  }
  &:focus {
    box-shadow: 0 0 0 2px var(--button-blue);
  }
  &.ToolIcon--selected {
    background-color: var(--button-gray-2);
    &:active {
      background-color: var(--button-gray-3);
    }
  }

  &--show {
    visibility: visible;
  }
  &--hide {
    visibility: hidden;
  }
}

.ToolIcon_type_radio,
.ToolIcon_type_checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;

  &:checked + .ToolIcon__icon {
    background-color: var(--button-gray-2);
  }
  &:focus + .ToolIcon__icon {
    box-shadow: 0 0 0 2px var(--button-blue);
  }
  &:active + .ToolIcon__icon {
    background-color: var(--button-gray-3);
  }
}

.ToolIcon_type_floating {
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
  &:active {
    background-color: transparent;
  }
  &:focus {
    box-shadow: none;
  }
  .ToolIcon__icon {
    width: 2rem;
    height: 2em;
  }
}

.ToolIcon.ToolIcon__lock {
  &.ToolIcon_type_floating {
    margin-left: 0.1rem;
  }
}

.ToolIcon__keybinding {
  position: absolute;
  bottom: 2px;
  right: 3px;
  font-size: 0.5em;
  color: var(--button-gray-3);
  font-family: var(--ui-font);
  user-select: none;
}

@media (max-width: 425px) {
  .Shape .ToolIcon__icon {
    width: 2rem;
    height: 2rem;

    svg {
      height: 0.8em;
    }
  }
}

@media (max-width: 360px) {
  .ToolIcon.ToolIcon__lock {
    display: inline-block;
    position: absolute;
    top: 60px;
    right: -8px;

    margin-left: 0;
    border-radius: 20px 0 0 20px;

    background-color: var(--button-gray-1);
    &:hover {
      background-color: var(--button-gray-1);
    }
    &:active {
      background-color: var(--button-gray-2);
    }

    .ToolIcon__icon {
      border-radius: inherit;
    }
    svg {
      position: static;
    }
  }
}

.unlocked-icon {
  :root[dir="ltr"] & {
    left: 2px;
  }
  :root[dir="rtl"] & {
    right: 2px;
  }
}
